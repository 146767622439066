<template>
  <b-row>
    <b-col cols="12" class="mb-2">
      <b-card>
        <h6 class="section-label mx-1 mb-2">Categories</h6>
      </b-card>
    </b-col>

    <b-col cols="12" class="mb-2" v-if="invoice">
      <h5>Invoice:</h5>
      <b-card>
        {{                                 invoice                                 }}
      </b-card>
    </b-col>
    <b-col cols="8" class="mb-2">
      <h5>Subscriptions Plans</h5>
      <b-card>
        <b-list-group-item v-for="(transaction, transactionIndex) in transactions" :key="transactionIndex" tag="li">
          <template v-if="transaction.object != 'invoiceitem'">

            <div class="d-flex">
              <div class="w100">
                <b-card-text class="font-weight-bold mb-0">
                  Subscription


                  € {{ parseFloat(transaction.plan.amount / 100).toFixed(2) }}
                  <strong v-if="!transaction.plan.active" class="text-danger"> (Subscription cancelled)</strong><br />
                  from:
                  <small>{{ toRealDate(transaction.current_period_start) }}</small> to: <small>{{ toRealDate(transaction.current_period_end) }}</small>
                  <hr>
                  cancel-start:{{ transaction.current_period_start }}<br />
                  cancel-end: {{ transaction.cancel_at_period_end }}<br />
                  cancel-at: {{ transaction.canceled_at }}<br />
                </b-card-text>

              </div>

              <div @click="getInvoice(transaction.latest_invoice)" class="ml-25 btn btn-primary pull-right">
                Latest Invoice
              </div>
            </div>
          </template>
        </b-list-group-item>
      </b-card>
    </b-col>
    <b-col cols="4" class="mb-2">
      <h5>Single Payments</h5>
      <b-card>
        <b-list-group-item v-for="(transaction, transactionIndex) in transactions" :key="'single' + transactionIndex" tag="li">
          <template v-if="transaction.object == 'invoiceitem'">

            <div class="d-flex">
              <div class="w100">
                <b-card-text class="font-weight-bold mb-0">
                  {{ transaction.description }} <br />
                  {{ toRealDate(transaction.date) }}
                </b-card-text>

              </div>
              <div @click="getInvoice(transaction.invoice)" class="ml-25 btn btn-primary pull-right">
                Invoice
              </div>
            </div>
          </template>
        </b-list-group-item>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BAvatar, BBadge, BFormGroup, BListGroupItem, BCardText, BCard, BRow, BCol } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BAvatar, BCardText,
    BBadge,
    BFormGroup, BListGroupItem,
  },
  data() {
    return {
      transactions: "",
      invoice: null
    };
  },
  methods: {
    toRealDate(timestamp) {
      return moment(timestamp, 'X').format('HH:mm DD/MM/YYYY')
    },
    getInvoice(id) {
      useJwt
        .subscriptions('invoice/' + id)
        .then((response) => {
          window.open( response.data.data.hosted_invoice_url, '_BLANK');
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't downlad your transactions data from the server.`,
            },
          });
        });
    }
  },
  async created() {
    useJwt
      .subscriptions('all/' + JSON.parse(localStorage.getItem('userData')).id)
      .then((response) => {
        this.transactions = response.data.data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your transactions data from the server.`,
          },
        });
      });
  },
};
</script>